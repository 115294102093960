import axios from 'axios';
import { requestOptions } from './UserToken';

axios.defaults.baseURL = 'https://app.flingbees.com:8443/flingBees/v1/api/';
// axios.defaults.headers.common['Authorization'] = localStorage.getItem('Token');
if (!!localStorage.getItem('Token')) {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('Token');
}

export const setLocal = Token => {
  axios.defaults.headers.common['Authorization'] = Token;
  console.log(Token, 'localtest');
};
const login = data => {
  return axios.post(`auth/admin`, data);
};
const register = data => {
  return axios.post(`admin/add`, data);
};

const adminData = data => {
  return axios.post(`admin/list`, data);
};

const users = data => {
  return axios.post(`admin/list-user`, data);
};
const ViewMore = id => {
  return axios.get(`admin/details/${id}`);
};

const promo = data => {
  return axios.post(`admin/coupon-code`, data);
};

const PromoUpdate = data => {
  return axios.put(`admin/coupon-code`, data);
};

const listPromo = data => {
  return axios.post(`admin/coupon-code/list`, data);
};
const advertisement = data => {
  return axios.post(`admin/advertisement`, data);
};

const Support = () => {
  return axios.get(`support/admin/list`);
};
const ManageAid = data => {
  return axios.post(`admin/promotion/list`, data);
};
const AidInquiry = data => {
  return axios.post(`promotion-inquiry/list`, data);
};
const ListPlan = () => {
  return axios.get(`admin/premium-plans/all`);
};

const ListPaymentMobile = data => {
  return axios.post(`payment/list`, data);
};

const ViewMorePlanMobile = id => {
  return axios.get(`payment/detail/${id}`);
};
// graph api

const GraphMale = () => {
  return axios.get(`admin/countByGender/Male`);
};
const GraphFemale = () => {
  return axios.get(`admin/countByGender/Female`);
};
const GraphOthers = () => {
  return axios.get(`admin/countByGender/Others`);
};
const GraphCountryList = () => {
  return axios.get(`admin/country`);
};
const GraphCountryCount = value => {
  return axios.get(`admin/country/${value}`);
};

//graph api end
const listRoles = () => {
  return axios.get(`admin/role/list`);
};
const listCoins = () => {
  return axios.get(`admin/coin/admin/list-coins`);
};

const CoinsAdd = data => {
  return axios.post(`admin/coin`, data);
};
const CoinsUpdate = data => {
  return axios.put(`admin/coin`, data);
};
const PlansUpdate = data => {
  return axios.put(`admin/premium-plans`, data);
};
const TotalUsers = () => {
  return axios.get(`admin/count-user`);
};
const TotalPremium = () => {
  return axios.get(`admin/count-premium `);
};
const TotalTrial = () => {
  return axios.get(`admin/count-trial `);
};
const TotalToday = () => {
  return axios.get(`admin/count/today-added-user `);
};
const DropDown = () => {
  return axios.get(`admin/role/drop-down`);
};
const RoleAdd = data => {
  return axios.post(`admin/role/add`, data);
};

const PlanAdd = data => {
  return axios.post(`admin/premium-plans`, data);
};
const Mass = data => {
  return axios.post(`notification`, data);
};
const PromoDisable = data => {
  return axios.post(`admin/coupon-code/disable`, data);
};

const PromoActivate = data => {
  return axios.post(`admin/coupon-code/activate`, data);
};
const UserActivate = data => {
  return axios.post(`admin/activate-user`, data);
};
const UserDisable = data => {
  return axios.post(`admin/disable-user`, data);
};
const AdminActivate = data => {
  return axios.post(`admin/activate`, data);
};
const AdminDisable = data => {
  return axios.post(`admin/disable`, data);
};
export {
  login,
  register,
  promo,
  users,
  listPromo,
  Support,
  TotalUsers,
  DropDown,
  RoleAdd,
  PlanAdd,
  listRoles,
  listCoins,
  GraphMale,
  GraphFemale,
  GraphOthers,
  CoinsAdd,
  advertisement,
  ViewMore,
  ViewMorePlanMobile,
  ListPlan,
  ListPaymentMobile,
  CoinsUpdate,
  PlansUpdate,
  ManageAid,
  TotalPremium,
  TotalTrial,
  TotalToday,
  Mass,
  PromoUpdate,
  PromoDisable,
  PromoActivate,
  UserActivate,
  UserDisable,
  adminData,
  AdminActivate,
  AdminDisable,
  GraphCountryList,
  GraphCountryCount,
  AidInquiry,
};
export { axios };
