import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import { routerReducer } from 'react-router-redux';
import { login } from '../reducers/loginReducer';

export default combineReducers({
  login,
  settings: settingsReducer,
  routing: routerReducer,
});
