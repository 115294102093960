import {
  LOGIN_ACTION_FAILURE,
  LOGIN_ACTION_REQUEST,
  LOGIN_ACTION_SUCCESS,
} from '../constants/actionTypes';
import { setLocal } from '../API/Api';

const initialState = {
  fetching: false,
  success: false,
  failure: false,
  token: null,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTION_REQUEST:
      return { ...state, fetching: true };

    case LOGIN_ACTION_SUCCESS:
      const Token = `Bearer ${action.data.data.accessToken}`;
      localStorage.setItem('Token', Token);
      setLocal(Token);
      console.log(action, 'action');
      return { ...state, fetching: false, success: true, token: action.data.data.accessToken };

    case LOGIN_ACTION_FAILURE:
      return { ...state, fetching: false, failure: true };
    default:
      return { ...state };
  }
};
